import React, {useState, useEffect} from "react";
import {JurisdictionService} from "@common/units-api";
import {Button} from '@common/components';
import {authManager} from "@common/authentication";
import {useToasts} from "react-toast-notifications";
import * as _ from "lodash";
import "../App.css";

export const JurisdictionUnitDetail: React.FunctionComponent<any> = () => {
    const token = authManager.getJwt();
    const info = authManager.getInfoFromAdmin();
    const jurisdictionService = new JurisdictionService(token);
    const [displayJurisdiction, setDisplayJurisdiction] = useState("Loading...");
    const [isSyncing, setIsSyncing] = useState(false);
    const {addToast} = useToasts();

    const getJurisdiction = async (unitJurisdictions: Array<any>): Promise<Array<any>> => {
        const jurisdictions = [];
        for (let unitJurisdiction of unitJurisdictions) {
            if (!_.isNil(unitJurisdiction.attributes.jurisdiction_id)) {
                jurisdictions.push({
                    ...(await jurisdictionService.getJurisdiction(unitJurisdiction.attributes.jurisdiction_id)),
                    isMain: unitJurisdiction.attributes.main,
                });
            }
        }

        return jurisdictions;
    };

    const getJurisdictionTypes = async (): Promise<Array<any>> => {
        const jurisdictionTypes = await jurisdictionService.getJurisdictionTypes();
        let jurisdictionTypeMapped = jurisdictionTypes.map((jt) => {
            return {id: jt.id, name: jt.attributes.name, hierarchy_rank: jt.attributes.hierarchy_rank};
        });

        // We need to remove Block Group from the types
        jurisdictionTypeMapped = jurisdictionTypeMapped.filter((j) => j.name.toLowerCase() !== "block group");
        return _.sortBy(jurisdictionTypeMapped, ["hierarchy_rank"]).reverse();
    };

    const loadJurisdictionData = async () => {
        try {
            const legacyUnitId = info["unitId"];
            const unitJurisdictions = await jurisdictionService.getJurisdictionUnits(legacyUnitId);
            if (_.isNil(unitJurisdictions)) {
                setDisplayJurisdiction("Not found");
                return;
            }

            const jurisdictions = await getJurisdiction(unitJurisdictions);

            if (jurisdictions.length === 0) {
                setDisplayJurisdiction("Jurisdiction not found for this unit");
                return;
            }
            const mainJurisdiction = jurisdictions.find((j) => j.isMain === true);
            if (_.isNil(mainJurisdiction)) {
                setDisplayJurisdiction("Not found");
                return;
            }

            if (mainJurisdiction.data.attributes.name?.toLowerCase() === "international") {
                setDisplayJurisdiction("International");
                return;
            }

            let jurisdictionTypes = await getJurisdictionTypes();                let jurisdictionText = "";
            for (let jurisdictionType of jurisdictionTypes) {
                const currentJurisdiction = jurisdictions.find((j) => j.data.attributes.jurisdiction_type_id === jurisdictionType.id);
                if (_.isNil(currentJurisdiction)) {
                    continue;
                }

                // When displaying the unit jurisdiction we must not include the levels (jurisdiction types) prior to the main/primary jurisdiction
                // for that we check if the string includes a "primary" before concatenating the other levels of jurisdiction
                const jurisdictionTypeName = jurisdictionType.name === "Place" ? "City/Town" : jurisdictionType.name;
                if (currentJurisdiction.isMain) {
                    jurisdictionText = `${jurisdictionText} <strong>${currentJurisdiction.data.attributes.name} (${jurisdictionTypeName}) (primary)</strong> > `;
                } else if (jurisdictionText.includes("primary")) {
                    jurisdictionText = `${jurisdictionText} ${currentJurisdiction.data.attributes.name} (${jurisdictionTypeName}) > `;
                }
            }

            // Set state before updating jurisdiction
            const jurisdictionForState = _.get(jurisdictions, "[0].data.attributes.state", null);
            if (!_.isNil(jurisdictionForState) && !_.isEmpty(jurisdictionForState)) {
                jurisdictionText = `${jurisdictionText} ${jurisdictionForState} (State) >`;
            }

            // Before setting the jurisdiction we clean the last two characters a blank space and a '>'
            setDisplayJurisdiction(jurisdictionText.substring(0, jurisdictionText.length - 2));
        } catch (e) {
            console.error(e);
            setDisplayJurisdiction("An error occurred while loading the jurisdiction.");
        }
    };

    useEffect(() => {
        loadJurisdictionData();
    }, []);

    const handleSync = async () => {
        setIsSyncing(true);
        try {
            const legacyUnitId = info["unitId"];
            const unitJurisdictions = await jurisdictionService.getJurisdictionUnits(legacyUnitId);
            
            for (let unitJurisdiction of unitJurisdictions) {
                await jurisdictionService.deleteJurisdiction(unitJurisdiction.id);
            }

            await jurisdictionService.syncJurisdiction(legacyUnitId);
            await new Promise(resolve => setTimeout(resolve, 5000));
            await loadJurisdictionData();

            addToast("Jurisdiction data synced and reloaded successfully!", {
                appearance: 'success',
                autoDismiss: true,
            });
        } catch (error) {
            console.error("Error syncing jurisdiction data:", error);
            addToast("Failed to sync jurisdiction data. Please try again later.", {
                appearance: 'error',
                autoDismiss: true,
            });
        } finally {
            setIsSyncing(false);
        }
    };

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <span className="h2">Jurisdiction</span> (US only)
                        </div>
                        <Button 
                            className="btn"
                            type="button"
                            variant="primary"
                            color="white"
                            onClick={handleSync} 
                            disabled={isSyncing}
                        >
                            {isSyncing ? "Syncing..." : "Sync"}
                        </Button>
                    </div>
                </div>
                <div className="col-md-12">
                    <div dangerouslySetInnerHTML={{__html: displayJurisdiction}} />
                </div>
            </div>
        </React.Fragment>
    );
};
